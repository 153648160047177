import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import colo from "../assets/img/colo.jpg";
import chignon from "../assets/img/chignon.jpg";
import coupe from "../assets/img/coupe.jpg";
import soin from "../assets/img/soin.jpg";

const Prestations = () => {
  return (
    <div className="page">
      <div className="top">
        <Navbar />
      </div>
      <div className="middle">
        <div className="titre_page"> Prestations</div>
        <div className="prestations">
          <div className="prestations_container">
            <div className="spacer"></div>
            <div className="prestations_container_box">
              <div className="prestations_container_box_img">
                <img src={coupe} />
              </div>
              <div className="prestations_container_box_txt">
                <h3>Coupe</h3>
                <p>
                  Ici, chaque coupe de cheveux est bien plus qu'une simple
                  routine : c'est un art. Nous nous engageons à vous offrir une
                  expérience unique, où votre style est au cœur de notre
                  savoir-faire. J'utilise des techniques précises et adaptées,
                  basées sur une formation continue et une passion pour les
                  nouvelles tendances.
                  <br /> Que vous recherchiez une coupe classique, moderne, ou
                  une transformation complète, nous prenons soin d'adapter nos
                  méthodes à la texture et à la structure naturelle de vos
                  cheveux, garantissant ainsi un résultat impeccable, qui
                  respecte votre personnalité.
                  <br /> Nous privilégions également des produits capillaires de
                  haute qualité, soigneusement sélectionnés pour leur efficacité
                  et leur respect de la santé de vos cheveux et de votre cuir
                  chevelu. Ces produits professionnels, riches en ingrédients
                  nourrissants, assurent à vos cheveux brillance, force, et
                  vitalité, tout en étant respectueux de l'environnement.
                </p>
              </div>
            </div>
          </div>
          <div className="prestations_container">
            <div className="prestations_container_box">
              <div className="prestations_container_box_txt">
                <h3>Soins</h3>
                <p>
                  Nous croyons que des cheveux sains sont la base d'une belle
                  coiffure. C'est pourquoi nous proposons une gamme complète de
                  soins capillaires adaptés à tous les types de cheveux et à
                  tous les besoins. Que vous souhaitiez hydrater, nourrir,
                  réparer ou revitaliser vos cheveux, notre équipe de
                  professionnels est là pour vous conseiller et vous offrir des
                  solutions sur mesure. 1. Soins Hydratants Les cheveux secs et
                  déshydratés ont besoin d'une attention particulière. Nos soins
                  hydratants nourrissent en profondeur la fibre capillaire, leur
                  redonnant souplesse et brillance. Grâce à des ingrédients
                  riches en agents hydratants, vos cheveux retrouveront leur
                  douceur et leur éclat. 2. Soins Réparateurs Pour les cheveux
                  abîmés par les traitements chimiques ou la chaleur, nous
                  proposons des soins réparateurs qui ciblent les zones
                  fragilisées. <br />
                  Ces traitements aident à restaurer la structure interne du
                  cheveu, réduisant les cassures et prévenant les fourches, tout
                  en rendant vos cheveux plus forts et plus résistants. 3. Soins
                  Revitalisants Offrez à vos cheveux un coup de frais avec nos
                  soins revitalisants. Ils sont spécialement conçus pour
                  redonner vie aux cheveux ternes et fatigués. Grâce à des
                  formules enrichies en vitamines et en antioxydants, vos
                  cheveux retrouveront éclat et vitalité. 4. Soins Scalp La
                  santé de vos cheveux commence par celle de votre cuir chevelu.
                  Nos soins dédiés au cuir chevelu permettent d'équilibrer, de
                  purger et de revitaliser cette zone souvent négligée. Que vous
                  ayez un cuir chevelu sensible, gras ou sec, nous avons le soin
                  qu'il vous faut. 5. Soins personnalisés Chaque cheveu est
                  unique, et c'est pourquoi nous proposons également des soins
                  personnalisés, adaptés à vos besoins spécifiques. Nos
                  coiffeurs experts évalueront l'état de vos cheveux et de votre
                  cuir chevelu pour vous recommander le traitement le plus
                  approprié. <br />
                  <br />
                  Pourquoi Choisir nos Soins ? Produits de qualité : <br />
                  <br />
                  Nous utilisons uniquement des produits professionnels de haute
                  qualité, riches en ingrédients naturels et respectueux de la
                  santé de vos cheveux. Savoir-faire professionnel : Notre
                  équipe est formée aux dernières techniques de soin et reste à
                  l’affût des innovations du secteur pour vous offrir les
                  meilleures solutions. <br />
                  Moment de détente : En plus de bénéficier de soins de qualité,
                  profitez d'un moment de détente dans notre salon. Laissez-vous
                  chouchouter tout en prenant soin de vos cheveux. Chez
                  L'atelier d'Angèle, nous nous engageons à vous fournir les
                  meilleurs soins pour des cheveux éclatants de santé.
                  <br />
                  Prenez rendez-vous avec nous et offrez à vos cheveux
                  l’attention qu'ils méritent !
                </p>
              </div>
              <div className="prestations_container_box_img">
                <img src={soin} />
              </div>
            </div>
            <div className="spacer"></div>
          </div>
          <div className="prestations_container">
            <div className="spacer"></div>
            <div className="prestations_container_box">
              <div className="prestations_container_box_img">
                <img src={colo} />
              </div>
              <div className="prestations_container_box_txt">
                <h3>Colo</h3>
                <p>
                  Nous croyons qu'une belle couleur de cheveux ne se résume pas
                  à un simple changement de teinte, mais qu'elle est le reflet
                  de votre personnalité et de votre style unique.
                  <br /> C’est pourquoi nous mettons tout notre savoir-faire et
                  notre sens du détail au service de votre transformation
                  capillaire. <br />
                  Nos coloristes experts prennent le temps de vous écouter et de
                  comprendre vos envies, afin de créer une couleur sur-mesure
                  qui vous sublime et s'accorde parfaitement à votre carnation,
                  votre coupe, et votre style de vie. Qu'il s'agisse d'un
                  balayage naturel, d'une coloration audacieuse, ou de nuances
                  subtiles, nous travaillons avec précision pour atteindre le
                  résultat que vous désirez. Nous n'utilisons que des produits
                  de coloration de haute qualité, formulés pour apporter
                  brillance et douceur à vos cheveux tout en respectant leur
                  nature.
                  <br /> Nos produits contiennent des actifs nourrissants qui
                  préservent la santé de la fibre capillaire, vous assurant une
                  couleur intense et durable sans compromettre la vitalité de
                  vos cheveux. Venez découvrir l'art de la coloration chez
                  L'atelier d'Angèle, où votre couleur est créée pour révéler
                  votre beauté, votre personnalité et vous offrir un éclat
                  incomparable.
                </p>
              </div>
            </div>
          </div>
          <div className="prestations_container">
            <div className="prestations_container_box">
              <div className="prestations_container_box_txt">
                <h3>Chignon</h3>
                <p>
                  Nous savons que le jour de votre mariage est un moment unique,
                  où chaque détail compte pour faire de vous la plus belle
                  version de vous-même. <br />
                  C’est pourquoi nous mettons toute notre expertise et notre
                  créativité au service de la réalisation de votre chignon de
                  mariage, pour vous offrir une coiffure qui restera
                  inoubliable. Nos coiffeurs spécialisés dans les chignons de
                  mariée sont à l'écoute de vos envies et de votre style. Que
                  vous rêviez d'un chignon romantique et bohème, d'une coiffure
                  élégante et sophistiquée, ou d'un look naturel et raffiné,
                  nous créons des chignons sur-mesure qui s'harmonisent
                  parfaitement avec votre robe, vos accessoires, et l'ambiance
                  de votre mariage. Chaque chignon est réalisé avec minutie, en
                  utilisant des techniques professionnelles pour garantir une
                  tenue parfaite tout au long de votre journée, du "oui" jusqu'à
                  la piste de danse.
                  <br /> Nous utilisons des produits de fixation de qualité pour
                  assurer une coiffure qui reste belle et confortable, même dans
                  l'émotion et l'effervescence de votre célébration.
                  Laissez-nous sublimer votre beauté avec un chignon de mariage
                  qui reflète votre personnalité et magnifie ce jour
                  exceptionnel. À L'atelier d'Angèle, nous sommes là pour
                  transformer vos rêves de mariée en réalité, avec une coiffure
                  à la hauteur de votre bonheur.
                </p>
              </div>
              <div className="prestations_container_box_img">
                <img src={chignon} />
              </div>
            </div>
            <div className="spacer"></div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default Prestations;
