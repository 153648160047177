import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import Welcome from "../components/Welcome";

// function HomePaveContainer() {
//   const navigate = useNavigate();

//   return (
//     <div className="home_pave_box">
//     <div className="home_pave_container">
//       <div className="home_pave grid1" onClick={() => navigate('/salon')}>Le salon</div>
//       <div className="home_pave grid2" onClick={() => navigate('/prestations')}>Prestations</div>
//       <div className="home_pave grid3" onClick={() => navigate('/photos')}>Photos</div>
//       <div className="home_pave grid4" onClick={() => navigate('/tarifs')}>Tarifs</div>
//       <div className="home_pave grid5" onClick={() => navigate('/contact')}>Contact</div>
//     </div>
//     </div>
//   );
// }

const Home = () => {
  return (
    <div className="page">
      <div className="top">
        <Navbar />
      </div>
      <div className="middle">
        {/* <div className="titre_page"> Accueil</div> */}
        <div className="middle_container">
          <div className="mc_presa">
            {/* <p>
              Bienvenue sur le site du salon de coiffure "L'atelier d'Angèle"
            </p> */}
          </div>
          <Welcome />
          {/* <HomePaveContainer /> */}
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
