import React from "react";
import logo from "../assets/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFacebook } from "@fortawesome/free-brands-svg-icons";
import { FaAddressCard } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoShareSocialOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_part">
        <img src={logo} alt="Logo de l'entreprise" />
      </div>
      <div className="footer_part">
        <FaAddressCard />
        <br />
        {/* <h3>Me trouver</h3> */}
        <p>Rue de la station, 40</p>
        <p>1350 Orp Le grand</p>

        <p>
          <a
            className="footer_part_link"
            href="https://www.google.com/maps/dir/?api=1&destination=50.696840,4.987384"
            target="_blank" // Ouvre le lien dans un nouvel onglet
            rel="noopener noreferrer" // Sécurité supplémentaire pour les liens externes
          >
            Cliquer ici pour l'itinéraire avec Google Maps
          </a>
        </p>
      </div>
      <div className="footer_part">
        <FaPhoneVolume />
        <br />
        {/* <h3>Me contacter</h3> */}
        <p>Mobile : 0497/32.23.44</p>
        <p>
          {" "}
          Mail :{" "}
          <a href="mailto:angelinegautier@hotmail.com">
            angelinegautier@hotmail.com
          </a>
        </p>
      </div>
      <div className="footer_part">
        {/* <IoShareSocialOutline /> */}

        <a
          href="https://www.facebook.com/profile.php?id=100063605682232"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faSquareFacebook} size="2x" />{" "}
          {/* Taille de l'icône */}
        </a>
      </div>
    </div>
  );
};

export default Footer;
