import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import angele from "../assets/img/angele.jpg";

const Contact = () => {
  return (
    <div className="page">
      <div className="top">
        <Navbar />
      </div>
      <div className="middle">
        <div className="titre_page"> Contact</div>
        <div className="contact_container">
          <div className="contact_container_up">
            <div className="contact_container_txt">
              Que vous ayez besoin d'un rendez-vous, de conseils personnalisés
              ou simplement d'en savoir plus sur nos services, nous sommes à
              votre écoute.
              <br /> N'hésitez pas à nous contacter par téléphone, par e-mail,
              ou à passer directement au salon pour échanger avec notre équipe.
              Nous serons ravis de vous accueillir et de vous aider à trouver la
              coupe ou le soin parfait pour vous. À très bientôt !
            </div>
          </div>
          <div className="contact_container_infos">
            <div className="contact_container_box">
              <h3>Me contacter</h3>
              <div className="me_contacter">
                <div className="mc_img">
                  <img src={angele} />
                </div>
                <div className="mc_texte">
                  <p>Tel : 0497/32.23.44</p>
                  <p>
                    <p>
                      {" "}
                      Mail :{" "}
                      <a href="mailto:angelinegautier@hotmail.com">
                        angelinegautier@hotmail.com
                      </a>
                    </p>
                    <br />
                    Rue de la station,34 <br />
                    1350 Orp-Le-Grand
                  </p>
                </div>
              </div>
              <div className="space" />
            </div>
            <div className="contact_container_box">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2527.236413087671!2d4.984960077806865!3d50.69699727164033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c10cd55c6abbeb%3A0xe7d05982780fa812!2sRue%20de%20la%20Station%2044%2C%201350%20Orp-Jauche!5e0!3m2!1sfr!2sbe!4v1716215899088!5m2!1sfr!2sbe"
                width="450"
                height="337"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
